.box-management {
    color: #1B0E44;
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.box-bg {
    background-image: url('../../../asset/background-blue.svg');
}

.logo-area {
    width: 130px;
    margin-bottom: 65px;
}

.central-area-reset {
    background-color: #FFFFFF;
    height: auto;
    width: auto;
    /* height: 488px;
    width: 415.5px; */
    border-radius: 12px;
    /* padding: 40px; */
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
}

.key-area{
    /* background-color: gray; */
    display: flex;
    justify-content: center;
}

.text-info-area {
    /* background-color: yellow; */
    margin-top: 50px;
    /* margin-bottom: 29px; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    font-family: Rubik, serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #666666;

}

.new-pass-area-reset {
    /* background-color: gray; */
    height: 140px;
    margin-top: 35px;
    margin-bottom: 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.information-elements {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.information-elements a {
    /* margin-top: 10px; */
    margin-bottom: 10px;
    font-weight: 500;


}

.information-elements span {
    font-weight: 400;
    margin-right: 5px;

}

.information-elements, 
.information-elements a {
    color: #FFFFFF;
    font-family: Rubik;
    font-size: 13px;
    font-style: normal;

    font-family: Rubik, serif;
    font-size: 13px;
    font-style: normal;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
}

.box-management {
  color: #1b0e44;
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.box-bg {
  background-image: url("../../../asset/background-blue.svg");
}

.logo-area-login {
  margin-top: 60px;
}

.key-area {
  display: flex;
  justify-content: center;
}

.text-info-area-for {
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-family: Rubik, serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #666666;
}
.create {
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}
.create a {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  margin-left: 10px;
  text-decoration: underline;
}

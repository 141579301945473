.review {
  background: rgba(106, 176, 76, 0.1);
  border-radius: 8px;
  width: 150x;
  height: 32px;
  padding: 10px 15px;
  color: #6ab04c1a;
  float: right;
}
.review a {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #6ab04c;
  text-decoration: none;
}


.button:disabled {
  opacity: 0.8;
}

.button {
  background-color: #1B0E44;
  width: 100%;
  height: 48px;
  border: none;
  color: #fff;
  border-radius: 8px;
  transition: all 0.3s;
}

.button:hover {
  opacity: 0.8;
}

.title {
  font-size: 12px;
  font-family: 'Rubik', sans-serif;;
  color: #666666;
}
.description {
  font-size: 15px;
  font-family: 'Rubik', sans-serif;;
  color: #000000;
}

.mt24 {
  margin-top: 24px;
}

.radius24 {
  border-radius: 24px;
}

.graph {
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 25px 35px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  padding-right: 20px;
}
.title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #a2a7b4;
  margin-bottom: 20px;
}
hr {
  width: 28px;
  height: 7.2px;
  border-radius: 9px;
}
.gContainer {
  display: flex;
  align-items: center;
  margin-left: 50px;
  padding-top: 30px;
}

.billDdl {
  width: 20%;
  border: 1px solid #4650e5;
  box-sizing: border-box;
  border-radius: 4px;
}
.review {
  background: rgba(106, 176, 76, 0.1);
  border-radius: 8px;
  width: 150x;
  height: 32px;
  padding: 10px 15px;
  color: #6ab04c1a;
  float: right;
}
.review a {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #6ab04c;
  text-decoration: none;
}
.btn {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  color: #130f40;
  border: 0.8px solid #1b0e44;
  background-color: #fff;
  padding: 3% 5%;
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
}
.btn:disabled {
  cursor: not-allowed;
  background-color: rgb(241, 237, 237);
  border: none;
}
.boxCore {
  flex-direction: row !important;
}
.selectContainer {
  padding: 1%;
  padding-left: 0;
  width: 300px;
}
.selectContainer label {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #9b9b9b;
  text-transform: capitalize;
  float: left;
}
.selectItem {
  display: block;
  width: 90%;
  padding: 10px 8px;
  padding-right: 10px;
  outline: none;
  border: none;
  border: 0.9px solid #4650e5;
  border-radius: 4px;
}
.boxCore2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 50px;
}
.inputCard {
  width: 100%;
  min-height: 383px;
}
.inputCard {
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
}
.closeBtn {
  outline: none;
  border: 1px solid #6ab04c;
  color: #6ab04c;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  padding: 2px;
  background: transparent;
  margin-bottom: 5px;
  border-radius: 5px;
}
.viewd {
  background: rgba(106, 176, 76, 0.1);
  border-radius: 8px;
  color: #6ab04c1a;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #6ab04c;
  padding: 5px;
  border: none;
  outline: none;
}
.scrollableCard {
  max-height: 450px;
  overflow-x: scroll;
}

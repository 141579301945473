.dropdown {
  width: 100%;
  padding: 16px 16px;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 4px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #8d9091;
  margin: 0 auto;
}

.dropdownDisabled {
  opacity: 0.7;
  cursor: not-allowed;
  flex-direction: row !important;
}

.navContainer {
  display: flex;
  align-items: center;
  padding-top: 10px;
}
.linksContainer {
  width: 80%;
  display: flex;
  align-items: center;
  margin: 0 auto;
}
.logo {
  width: 10%;
  cursor: pointer;
}
.dashboardLogo {
  height: auto;
  width: 100%;
}
.linksContainer a {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #474040;
  text-decoration: none;
  padding: 2% 0%;
  width: 120px;

  display: flex;
  justify-content: center;
}
.linksContainer a:active,
.linksContainer a.active {
  font-weight: 500;
  color: #161616;
  border-top: 4px solid #161616;
}

.buttonContainer {
  width: 7%;
}
.button {
  float: right;
  background-color: #f9eaeb;
  border-radius: 8px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #c5292a;
  outline: none;
  border: none;
  padding: 8px 24px;
}

.container {
  width: 100%;
  height: 56px;
  background: #ffffff;
  border: 1px solid #e3e8ec;
  border-radius: 12px;
  border-radius: 10px;
  display: flex;
  padding: 5px;
  margin-bottom: 20px;
}
.Buttonfilter {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  width: 20%;
  background-color: transparent;
  border-right: 1px solid #e3e8ec;
  cursor: pointer;
}
.Buttonfilter span {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: rgba(106, 176, 76, 0.6);
  margin-left: 15px;
}

.searchContainer {
  width: 70%;
  display: flex;
  align-items: center;
  padding-left: 50px;
}
.buttonFilter {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 120%;
  color: #9b9b9b;
  text-transform: capitalize;
  cursor: pointer;
  border: none;
  background: none;
}
.selectContainer {
  padding: 2%;
}
.selectContainer label {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #9b9b9b;
  text-transform: capitalize;
  float: left;
}
.searchIcon {
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
}
.searchInput {
  width: 70%;
  border: none;
  outline: none;
  margin-left: 40px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.selectButton {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 5% 0;
}
.selectButton .btn1 {
  width: 80px;
  height: 46px;
  background: #f3f4fb;
  border-radius: 8px;
  padding: 10px 20px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: #130f40;
  outline: none;
  border: none;
}
.selectButton .btn2 {
  width: 150px;
  height: 46px;
  background: #4834d4;
  border-radius: 8px;
  padding: 10px 20px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #ffffff;
  outline: none;
  border: none;
}

.searchInput::placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #cccccc;
}
.buttonContainer {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonContainer button {
  outline: none;
  border: none;
  background: #4834d4;
  border-radius: 8px;
  width: 90%;
  height: 32px;
}
button a {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  color: #ffffff;
}
button a:hover {
  color: #ffffff;
  text-decoration: none;
}
.filterButton {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  color: #ffffff;
}

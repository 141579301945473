html {
  background-color: #f7f8f9 !important;
}

.boxManagement {
  color: #1b0e44;
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.boxBg {
  background-image: url("../../asset/background-blue.svg");
}

.logoAreaLogin {
  margin-bottom: 15px;
  margin-top: 40px;
}

.textArea {
  color: #1b0e44;
  font-family: Rubik, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 145%;
  text-align: left;
}

.textFormArea {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.checkboxArea {
  margin-bottom: 35px;
}

.informationElements {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 200px;
}

.informationElements a {
  font-weight: 500;
}

.informationElements,
.informationElements a {
  color: #ffffff;
  font-family: Rubik;
  font-size: 13px;
  font-style: normal;
  font-family: Rubik, serif;
  font-size: 13px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
}

.selected-label {
  width: 100%;
}
.cardCustom {
  height: auto;
  width: auto;
  border-radius: 12px;
  padding: 34px 30px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.06);
  margin: auto;
  box-sizing: border-box;
}

.boxCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 453px;
  height: auto !important;
  /* padding: 52px 45px !important; */
  /* max-width: -webkit-fill-available; */

  height: 320px;
  background: #ffffff;
  /* Shadow #1 */
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 25px 35px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
}

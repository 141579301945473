.upload-selected {
    background-color: #ffffff;
    border: 1px solid #4650E5;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Rubik, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 8px;
    color: f5f6fa;
    position: relative;
    cursor: pointer;

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    height: 48px;

}

/* .upload-label {

} */

.dotted-fade {
    border: 1px dashed #E3E3E3;
    color: #8D9091;
}

.upload-profile-image {
    font-family: Rubik;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    padding: 2px;
    height: auto;
    display: flex;
    justify-content: start;
    border: 0;
}

#page-content-wrapper {
  background-color: #f7f8f9;
  min-height: 100vh;
}

body {
  box-sizing: border-box;
}

.mainContainer {
  margin: 30px;
}

.modal {
  justify-content: center;
}

.modal-content {
  border: none !important;
}

.custom-card {
  background: #ffffff;
  box-shadow: 0px 5.82826px 101.995px rgba(0, 0, 0, 0.03),
    0px 0.729789px 92.5711px rgba(0, 0, 0, 0.015);
  border-radius: 12px;
  margin-top: -90px;
  min-height: 120px;
}

.details-title {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 24px;
  /* identical to box height, or 240% */
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #666666;
}

.details-text {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  display: flex;
  align-items: center;
  /* Primary / Black */
  color: #000000;
}

.details-sub {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  margin-bottom: 30px;
}

.box-core {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 50px;
}

.box-card-details {
  height: auto;
  width: 100%;
}

.details-sub .box-card-details {
  min-height: 260px;
}

.space-rule {
  margin: 20px;
}

.m-space:nth-child(1) {
  margin-right: 10px;
}

.m-space:nth-child(2) {
  margin-left: 10px;
}

.details-img {
  object-fit: cover;
  border-radius: 4px;
}

.details-header {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
}

.input-card {
  min-width: 482px;
  min-height: 383px;
}

.custom-input {
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
}

.custom-input::placeholder {
  color: #8d9091;
}

.settings-pseudo-body {
  padding: 0px !important;
  display: block !important;
}

.tile-box-management {
  color: #1b0e44;
  min-height: auto;
  max-width: 100%;
  flex-wrap: wrap;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 1380px) {
  .tile-box-management {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 992px) {
  .tile-box-management {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 826px) {
  .tile-box-management {
    grid-template-columns: repeat(1, 1fr);
  }
}

.tileContainer {
  display: flex;
  justify-content: space-between;
  min-height: 125px;
  width: auto;
  min-width: 254px;
  border-radius: 12px;
  /* margin-left: 20px; */
  padding: 16px;
  background: #ffffff;
  height: fit-content;
  margin-bottom: 20px;
  box-shadow: 0px 5.82826px 101.995px rgba(0, 0, 0, 0.03),
    0px 0.729789px 92.5711px rgba(0, 0, 0, 0.015);
  border-radius: 12px;
}

@media screen and (max-width: 850px) {
  .tileContainer {
    width: -webkit-fill-available;
  }
}

.billDdl {
  width: 20%;
  border: 1px solid #4650e5;
  box-sizing: border-box;
  border-radius: 4px;
}

.mainText {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 148%;
  color: #161616;
}

.textGray {
  color: #9b9b9b;
}

.ant-alert {
  word-wrap: normal !important;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #e5e5e5;
  height: fit-content;
}

.pull-right {
  margin-left: auto;
}

.ant-modal {
  width: 100% !important;
  max-width: 529px !important;
}

.ant-modal-content {
  border-radius: 24px;

}
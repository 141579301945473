.settingBox {
  color: #1b0e44;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.settingBody {
  padding: 50px 50px;
  min-height: auto;
  width: auto;
  min-width: 100%;
  display: flex;
  justify-content: center;
}

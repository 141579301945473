.container {
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 25px 35px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  margin: 10px 0;
  padding: 20px 0;
}
.selectContainer {
  padding: 1%;
  padding-left: 0;
  width: 300px;
}
.selectContainer label {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #9b9b9b;
  text-transform: capitalize;
  float: left;
}
.selectItem {
  display: block;
  width: 90%;
  padding: 10px 8px;
  padding-right: 10px;
  outline: none;
  border: 1px solid #efefef;
  border-radius: 4px;
  font-size: 14px;
  font-family: "Rubik";
  color: #8f8c8c;
}
.selectItem::placeholder {
  font-size: 12px;
}
.formContainer {
  display: flex;
  max-width: 70%;
  margin: 20px auto;
}
.buttonContainer {
  display: flex;
  justify-content: space-between;
  max-width: 40%;
  margin: 20px auto;
}
.buttonContainer button {
  border: none;
  outline: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 1.5715;
  font-family: "Rubik";
}
.btn1 {
  color: #22b02e;
  background-color: rgba(34, 176, 46, 0.1);
}
.btn2 {
  background-color: #c5292a1a;
  color: #c5292a;
}
.btn1:hover,
.btn2:hover {
  opacity: 0.7;
}

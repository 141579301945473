.heading {
  display: flex;
  align-items: center;
  height: 100px;
}
.headingImage {
  margin-right: 10px;
}

.heading span {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  height: auto;
  line-height: 132%;
  color: #161616;
}

.heading a {
  display: flex;
  align-items: center;
  margin-right: 20px;
  outline: none;
  border: none;
}

.btn {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  color: #130f40;
  border: 0.8px solid #1b0e44;
  background-color: #fff;
  padding: 3% 5%;
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
}
.btn:hover {
  text-decoration: none;
  color: #130f40;
}
.btn:disabled {
  cursor: not-allowed;
  background-color: rgb(241, 237, 237);
  border: none;
}
[data-disabled="true"] {
  cursor: not-allowed;
  background-color: rgb(241, 237, 237);
  border: none;
}

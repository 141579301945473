.serviceContainer {
  width: 250px;
  height: 316px;
  border-radius: 12px;
  padding: 24px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 25px 35px rgba(0, 0, 0, 0.03);
  display: grid;
  grid-template-rows: repeat(4, auto);
}
.serviceContainer h3 {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 145%;
  color: #0d0d0d;
  text-transform: uppercase;
  text-align: center;
}
.telco {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}
.telco img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}
.buttonActive {
  padding: 8px 16px;
  background: rgba(106, 176, 76, 0.08);
  border-radius: 8px;
  width: 71px;
  height: 28px;
  margin: 0 auto;
}
.buttonActive p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.2px;
  color: #6ab04c;
}
.buttonInactive {
  padding: 8px 16px;
  background: rgba(224, 32, 32, 0.08);
  border-radius: 8px;
  width: 71px;
  height: 28px;
  margin: 0 auto;
}
.buttonInactive p {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.2px;
  color: #e02020;
}
.buttonContainer {
  width: 216px;
  height: 36px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.buttonDelete {
  width: 90px;
  height: 100%;
  outline: none;
  background-color: #8d9091;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.2px;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  opacity: 0.8;
  color: #1b0e44;
}
.buttonEdit {
  width: 120px;
  height: 100%;
  outline: none;
  background-color: #1b0e44;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.2px;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
}

.buttonDelete:hover,
.buttonEdit:hover {
  opacity: 0.8;
}

.servicesMain {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.heading {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}
.container {
  position: relative;
  margin: 20px 0;
}
.containerInput {
  font-size: 12px;
  display: block;
  width: 100%;
  height: 50px;
  padding: 0 20px;
  background: #fff;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 4px;
  box-sizing: border-box;
}
.containerInput:focus {
  outline: none;
}
.containerInput:focus ~ .containerLabel {
  top: -8px;
  font-size: 13px;
}

.containerInput:not(:placeholder-shown) ~ .containerLabel {
  top: -8px;
  font-size: 13px;
}
.containerLabel {
  font-size: 13px;
  font-family: "Rubik";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 8px;
  padding: 0 5px;
  background: #fff;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.btnContainer {
  text-align: center;
}
.btnContainer button {
  padding: 10px 30px;
  width: 235px;
  height: 44px;
  background: #1b0e44;
  border-radius: 4px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
  outline: none;
  margin-top: 20px;
}
.btnContainer button:disabled {
  cursor: none;
  opacity: 0.8;
}

.selectContainer {
  padding-left: 0;
  width: 100%;
}
.selectContainer label {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #000000;
  text-transform: capitalize;
  margin-left: 20px;
}
.selectItem {
  display: block;
  width: 100%;
  padding: 10px 8px;
  outline: none;
  border: 1px solid #efefef;
  border-radius: 4px;
  font-size: 14px;
  color: #8f8c8c;
}
.selectItem::placeholder {
  font-family: "Rubik";
  font-size: 12px;
}
.fileLabel {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #000000;
  text-transform: capitalize;
  margin-left: 20px;
  display: block;
}
.files {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}
.fileLabel2 {
  background: #ffffff;
  border: 1px dashed #e3e3e3;
  border-radius: 4px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  width: 100%;
  color: #8d9091;
  padding: 10px 12px;
  cursor: pointer;
}
.adminButtonContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.adminButtonContainer button {
  border-radius: 8px;
  outline: none;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
}
.adminButtonContainer .addBtn {
  width: 35%;
  color: #1b0e44;
  padding: 10px 12px;
}
.adminButtonContainer .cancelBtn {
  width: 60%;
  color: #ffffff;
  background-color: #1b0e44;
  padding: 10px 12px;
}

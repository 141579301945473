.boxCore {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 50px;
}
.inputCard {
  width: 100%;
  min-height: 383px;
}
.inputCard {
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
}

.main {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 5.82826px 101.995px rgba(0, 0, 0, 0.03),
    0px 0.729789px 92.5711px rgba(0, 0, 0, 0.015);
  border-radius: 12px;
  height: fit-content;
  padding: 0 3% 3%;
}
.children {
  height: auto;
}

.header {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
}
.container {
  position: relative;
  margin: 20px 0;
}
.containerInput {
  font-size: 12px;
  display: block;
  width: 100%;
  height: 50px;
  padding: 0 20px;
  background: #fff;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 4px;
  box-sizing: border-box;
}
.containerInput:focus {
  outline: none;
}
.containerInput:focus ~ .containerLabel {
  top: -8px;
  font-size: 13px;
}

.containerInput:not(:placeholder-shown) ~ .containerLabel {
  top: -8px;
  font-size: 13px;
}
.containerLabel {
  font-size: 13px;
  font-family: "Rubik";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  color: #000000;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 8px;
  padding: 0 5px;
  background: #fff;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.btnContainer {
  text-align: center;
}
.btnContainer button {
  padding: 10px 30px;
  width: 235px;
  height: 44px;
  background: #1b0e44;
  border-radius: 4px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
  outline: none;
  margin-top: 20px;
}
.btnContainer button:disabled {
  cursor: none;
  opacity: 0.8;
}

.selectContainer {
  padding: 1%;
  padding-left: 0;
  max-width: 100%;
}
.selectContainer label {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #000000;
  text-transform: capitalize;
  margin-left: 20px;
}
.selectItem {
  display: block;
  width: 100%;
  padding: 10px 8px;
  outline: none;
  border: 1px solid #efefef;
  border-radius: 4px;
  font-size: 14px;
  color: #8f8c8c;
}
.selectItem::placeholder {
  font-family: "Rubik";
  font-size: 12px;
}
.deactiveBtn {
  width: 100px;
  height: 32px;
  background: rgba(224, 32, 32, 0.1);
  border-radius: 4px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  border: none;
  color: #e02020;
  text-transform: capitalize;
}
.activeBtn {
  width: 100px;
  height: 32px;
  background: rgba(34, 176, 46, 0.1);
  border-radius: 4px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  border: none;
  color: #22b02e;
  text-transform: capitalize;
}
.active {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  text-transform: capitalize;
  color: #27923e;
}
.inactive {
  color: #e02020;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  text-transform: capitalize;
}
.selectModules {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #8c94a9;
  margin: 40px 0;
}
.card {
  max-width: fit-content;
  margin-top: 30px;
}
/* .roleContainer {
} */
.roleContainer li {
  list-style: none;
  margin: 10px 20px;
  display: flex;
}
.roleContainer li label {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 132%;
  color: #9b9b9b;
  display: inline-block;
}
input[type="checkbox"] {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  margin-right: 10px;
}
.ToggleBtn.Mui-selected {
  background-color: rgba(34, 176, 46, 0.1);
  color: #22b02e;
}

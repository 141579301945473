.header {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 16px;
}
.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 16px;
}

.container {
  position: relative;
  margin: 16px 0;
  flex: 1;
}
.containerInput {
  font-size: 12px;
  display: block;
  width: 100%;
  height: 48px;
  padding: 0 20px;
  background: #fff;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 4px;
  box-sizing: border-box;
}
.containerInput:focus {
  outline: none;
}
.containerInput:focus ~ .containerLabel {
  top: -8px;
  font-size: 13px;
}

.containerInput:not(:placeholder-shown) ~ .containerLabel {
  top: -8px;
  font-size: 13px;
}
.containerLabel {
  font-size: 13px;
  font-family: "Rubik";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  color: #000000;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 8px;
  padding: 0 5px;
  background: #fff;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.button:disabled {
  opacity: 0.8;
}

.button {
  background-color: #1B0E44;
  width: 100%;
  height: 48px;
  border: none;
  color: #fff;
  border-radius: 8px;
  transition: all 0.3s;
}

.button:hover {
  opacity: 0.8;
}

.card {
  max-width: 484px;
  margin-top: 30px;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  margin-right: 10px;
}

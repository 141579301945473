.review {
  background: rgba(106, 176, 76, 0.1);
  border-radius: 8px;
  width: 109px;
  height: 32px;
  padding: 10px 15px;
  color: #6ab04c1a;
  float: right;
}
.review a {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #6ab04c;
  text-decoration: none;
}
